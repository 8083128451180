/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type InPageNotificationsConfig_formValues = {
    readonly inPageNotifications: {
        readonly enabled: boolean | null;
    } | null;
    readonly " $refType": "InPageNotificationsConfig_formValues";
};
export type InPageNotificationsConfig_formValues$data = InPageNotificationsConfig_formValues;
export type InPageNotificationsConfig_formValues$key = {
    readonly " $data"?: InPageNotificationsConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"InPageNotificationsConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InPageNotificationsConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "InPageNotificationsConfiguration",
            "kind": "LinkedField",
            "name": "inPageNotifications",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '02d8eb02519c74c11d78b1211c623562';
export default node;
